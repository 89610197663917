body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./img/original2.jpg');
  background-size: cover;
  overflow-x:hidden;
  box-sizing: border-box;
-moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  overflow-x:hidden;
}

.sliding-background  {
  background: url("./img/original3.jpg") repeat-x;
  background-size: contain;
  animation: slide 60s linear infinite;
  position: absolute;
  height: 1600px;
  width: 5076px;
  z-index: -1 ;
}

@keyframes slide{
  0%{
    transform: translate3d(0, 0, 0);
  }
  100%{
    transform: translate3d(-1692px, 0, 0);
  }
}