.App {
  text-align: center;
  position:absolute ;
  width: 100%;
  position: absolute; /* here, fixed or relative may work too*/
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: scroll; /* also, try auto */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jokes{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.jokeCard{
  margin-top:30px;
  margin-bottom: 30px;
  font-family: 'Fredoka One', cursive;
  font-size: large;

}

.cardstyle{
  width: 400px;
  border-radius: 3px;
  height: 220px;
  background-image: linear-gradient(#4e8afa,rgb(147, 176, 238));
  opacity: 0.94;

}

.setup{
  color:lightgreen;
}

.headerTitle{
  color:rgb(0, 0, 0);
  font-family: 'Bebas Neue', cursive;
  font-size: 80px;
}

.buttonGet{
  background-color: rgba(90, 87, 253, 0.89); /* Green */
  border: none;
  border-radius: 50px;
  color: rgb(72, 255, 240);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: 'Fredoka One', cursive;
  font-size: large;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.buttonGet:hover{
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

